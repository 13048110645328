import "./output.css";
import React from "react";
import "./custom.css";

class TransparentButton extends React.Component{

    constructor(props){
        super(props);
        this.props = props;
    }

    render(){
        return (
        <a href={this.props.href ? this.props.href : "#contact"} onClick={this.props.click ? this.props.click : function(){}} className={`hover:bg-[rgba(255,255,255,0.5)] flex items-center justify-center rounded-full p-3 mr-3 sm:mr-0 bg-[rgba(255,255,255,0.25)] w-full relative z-index:100 font-body ${this.props.whiteText ? "text-white" : "text-raisin"} font-bold`} >
            <div className={`flex items-center gap-2 text-left w-full ml-3 mr-3`}><span className="material-symbols-outlined">{this.props.icon}</span>{this.props.children}</div>
        </a>
        )
    }


}

export default TransparentButton