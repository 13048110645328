import "./output.css";
import React from "react";
import "./custom.css";

class BlankArticle extends React.Component{

    constructor(props){
        super(props);
        this.props = props;
    }

    render(){
        return (
            <div className={`${this.props.full ? "w-full" : ""} ${this.props.borderColor ? this.props.borderColor : "border-green"} border-[1.25rem] rounded-xl flex flex-col`} style={{"backgroundImage": `linear-gradient(to right, ${this.props.theme == "dark" ? "#413B3A" : "#ECD5D5"} 1px, transparent 1px),linear-gradient(to bottom, ${this.props.theme == "dark" ? "#413B3A" : "#ECD5D5"} 1px, transparent 1px)`, "backgroundSize": "30px 30px", "backgroundColor": this.props.theme == "dark" ? "#262322" : "#F2E3E3"}}>
                {/*<div className="text-raisin  font-bold flex h-[10%] flex-grow-0 flex-row justify-end p-1"><span className={`material-symbols-outlined`}>close</span></div>*/}
                <div className={`${this.props.theme == "dark" ? "text-white" : "text-raisin"} font-body font-bold p-5`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default BlankArticle;