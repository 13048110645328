import './App.css';
import "./output.css";
import Codecard from "./Codecard.js";
import TransparentButton from './TransparentButton';
import React from "react"
import ProjectCard from "./ProjectCard.js";
import Divider from "./Divider.js";
import Card from "./Card.js";
import Award from "./Award.js";
import SquareImage from './SquareImage.js';
import Article from "./Article.js";
import BlankArticle from "./BlankArticle.js";

class App extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      darkMode: true,
      scroll: 0,
      projectPt: 0,
      aboutPt: 0,
      awardsPt: 0
    }
    this.darkModeBtn = React.createRef();
    this.projectHeader = React.createRef();
    this.aboutHeader = React.createRef();
    this.awardsHeader = React.createRef();
  }

  toggleDarkMode = ()=>{
    this.setState((prev)=>({
      "darkMode": !prev.darkMode,
    }));
  }

  componentDidMount(){
   // var tween = KUTE.fromTo('#wave', {path: '#one' }, { path: '#two' }).start();
   var self = this;
   console.log(this.aboutHeader.current.getBoundingClientRect().top)
   self.setState((prev)=>({
    "projectPt": this.projectHeader.current.getBoundingClientRect().top,
    "aboutPt": this.aboutHeader.current.getBoundingClientRect().top,
    "awardsPt": this.awardsHeader.current.getBoundingClientRect().top
   }));
   window.addEventListener("scroll", function(){
    self.setState((prev)=>({
      "scroll": window.scrollY,
    }))
   })
  }

  componentDidUpdate(){
    if (this.state.darkMode){
      document.body.style.backgroundColor = "#262322";
      document.querySelector(":root").style.setProperty("--bg-line-color", "#413B3A");
      
      this.darkModeBtn.current.style.backgroundColor = "#FFFFFF";
      this.darkModeBtn.current.style.color = "#262322";
    }
    else{
      document.body.style.backgroundColor = "#F2E3E3";
      document.querySelector(":root").style.setProperty("--bg-line-color", "#ECD5D5")
      
      this.darkModeBtn.current.style.backgroundColor = "#262322";
      this.darkModeBtn.current.style.color = "#FFFFFF";
    }
  }

  render(){
    return (
      <div className="relative">
        
        {/*Navbar*/}
        <div className={`flex w-full items-center justify-center p-5 gap-4 fixed top-0 z-[20] ${this.state.scroll > 75 ? "bg-[rgba(0,0,0,0.4)]" : ""}`}>   
          
          <div className="justify-start flex-grow sm:flex hidden">
            <h3 style={{"display": this.state.scroll > 75 ? "" : "none"}} className="text-xl font-body font-bold justify-self-start text-white">Anthony Varkey</h3>
          </div>

          <a href="#home" className={`hover:sepia-[0.9] flex flex-shrink-0 flex-grow-0 relative items-center justify-center text-left rounded-full h-10 pr-3 pl-3 font-bold ${this.state.darkMode ? "text-raisin" : "text-white"} gap-2 font-body ${this.state.darkMode ? "bg-white" : "bg-raisin"} `}>
            <span className={`material-symbols-outlined ${this.state.darkMode ? "text-raisin" : "text-white"}`}>home</span>Home
          </a>

         {/*<a href="#home" className={`flex-grow-0 flex-shrink-0 rounded-full w-10 h-10 ${this.state.darkMode ? "bg-white" : "bg-raisin"}  flex justify-center items-center ${this.state.darkMode ? "text-raisin" : "text-white"}`}><span className="material-symbols-outlined">home</span></a>*/}
          
          {/*<a style={{"display": this.state.scroll > this.state.projectPt ? "" : "none"}} href="#about" className="flex flex-shrink-0 flex-grow-0 relative items-center justify-center text-left rounded-full h-10 pr-3 pl-3 font-bold text-white bg-red hover:bg-[rgba(185,39,54,1)] gap-2 font-body">
            <span className="material-symbols-outlined text-white">emoji_people</span>About
          </a>
          <a style={{"display": this.state.scroll > this.state.aboutPt && this.state.scroll < this.state.awardsPt ? "" : "none"}} href="#awards" className="flex flex-shrink-0 flex-grow-0 relative items-center justify-center text-left rounded-full h-10 pr-3 pl-3 font-bold text-white bg-red hover:bg-[rgba(185,39,54,1)] gap-2 font-body">
            <span className="material-symbols-outlined text-white">star</span>Awards
          </a>
          <a style={{"display": this.state.scroll > this.state.aboutPt && !(this.state.scroll > this.state.projectPt && this.state.scroll < this.state.awardsPt) ? "" : "none"}} href="#projects" className="flex flex-shrink-0 flex-grow-0 relative items-center justify-center text-left rounded-full h-10 pr-3 pl-3 font-bold text-white bg-red hover:bg-[rgba(185,39,54,1)] gap-2 font-body">
            <span className="material-symbols-outlined text-white">bolt</span>Projects
    </a>*/}
          <button ref={this.darkModeBtn} onClick={this.toggleDarkMode} className=" flex-shrink-0 flex-grow-0 rounded-full relative z-10 w-10 h-10 bg-white flex justify-center items-center text-raisin"><span className="material-symbols-outlined">{this.state.darkMode ? "light_mode" : "dark_mode"}</span></button>
        </div>

        <div id="home" className="absolute top-[-5%]"></div>

        {/*Header*/}
        <div className="flex w-screen relative z-5 justify-center mt-[6rem] sm:mt-20 mb-10">
            <Codecard bg="bg-tan" bg_text="text-ltan"  centerV>
              <div className="flex w-full sm:flex-nowrap flex-wrap items-center">
                <h3 className="font-body z-10 text-raisin font-bold text-[50px] sm:text-[100px] relative">An<span className="text-red">t</span>h<span className="text-red">o</span><span className="text-red">n</span><span className="text-red">y</span> Varkey</h3>
                <div className="sm:w-[40%] grid sm:grid-rows-4 sm:grid-cols-1 grid-cols-2 grid-rows-2 gap-4">
                  <TransparentButton href="#contact" icon="outgoing_mail">Contact</TransparentButton>
                  <TransparentButton href="#projects" icon="bolt">Projects</TransparentButton>
                  <TransparentButton href="#awards" icon="star">Awards</TransparentButton>
                  <TransparentButton href="/api/resume.pdf" icon="picture_as_pdf">Resume</TransparentButton>
                </div>
              </div>
              
            </Codecard>
        </div>
  

        {/*TLDR*/}
        <div className="w-screen relative z-5 justify-center flex mb-10">
          <Codecard bg="bg-teal" bg_text="text-lteal" scroll_pos="30" fat="min-h-[20rem]">
            <div className="flex w-full flex-wrap justify-center mb-2">
                <h3 className="font-body z-10 text-white font-bold text-2xl relative white">
                  Hi!
                </h3>
            </div>
            <div className="flex w-full sm:flex-row items-center sm:justify-center mb-2 gap-5">
                
                <img src="/api/portrait.jpg" className=" sm:w-60 sm:h-60 w-40 h-40 rounded-xl relative z-10"></img>
                <h3 className="font-body z-10 text-white font-bold text-xs sm:text-xl relative white">
                  I'm Tony (see the red letters above) 
                  <br></br>
                  <br></br>
                  2nd year CE student at the University of Michigan in Ann Arbor (Go Blue!) 
                  <br></br>
                  <br></br>
                </h3> 
            </div>
          </Codecard>
        </div>

        <div id="about" style={{position: "relative"}}>
        <div ref={this.aboutHeader} className="absolute top-[-5%]"></div>
        {/* <Divider></Divider> */}
        </div>

        {/*<div className="w-screen relative z-5 justify-center flex pl-5 mt-10 mb-10 pr-5 sm:flex-nowrap flex-wrap">
          <Codecard bg="bg-tan" bg_text="text-ltan" scroll_pos="80" fat="min-h-[10rem]" centerV>
            <div className="flex w-full sm:flex-nowrap flex-wrap justify-center items-center">
            <h3 className="font-body z-10 text-raisin font-bold text-[50px] sm:text-[75px] relative text-center ">About Me</h3>
            </div>
            <div className="flex w-full sm:flex-nowrap flex-wrap items-center justify-center text-center">
            <h3 className="font-body z-10 text-raisin font-bold text-xl text-center ">Just the basics</h3>
            </div>
          </Codecard>
        </div>*/}

        <div className="flex sm:flex-nowrap flex-wrap mb-5">
          

        {/*<div className="w-full relative z-5 justify-center flex pl-5 pr-5 sm:flex-nowrap flex-wrap  mb-5 sm:mb-0">
            <Codecard bg="bg-teal" bg_text="text-lteal" scroll_pos="100" noM fat="min-h-[42rem]">
              <div className="flex w-full sm:flex-nowrap flex-wrap justify-center items-center">
                <h3 className="font-body z-10 text-white font-bold text-[25px] sm:text-[50px] relative text-center ">Activities</h3>
              </div>
              <div className="flex w-full sm:flex-nowrap flex-wrap items-center text-center">
                <div className="grid grid-cols-1 gap-4 m-5">
                  <TransparentButton whiteText icon="read_more" href='https://agloa.org'>Academic Games</TransparentButton>
                  <TransparentButton whiteText icon="read_more" href='https://bpa.org'><span className="sm:text-[0.8rem] text-xs">Business Professionals of America</span></TransparentButton>
                  <TransparentButton whiteText icon="read_more" href='https://hurongamedevs.com'><span className="sm:text-base text-xs">Video Game Development Club</span></TransparentButton>
                  <TransparentButton whiteText icon="read_more">Symphony Orchestra (Viola)</TransparentButton>
                  <TransparentButton  whiteText icon="read_more" href='https://hurongamedevs.com'>Connect Me Tutoring</TransparentButton>
                  <TransparentButton  whiteText icon="read_more">Golf</TransparentButton>
                </div>
              </div>
            </Codecard>
            </div>*/}

          {/*<div className="w-full relative z-5 justify-center flex sm:flex-nowrap flex-wrap mb-5 pl-5 pr-5">
            <div className="flex w-full pr-5 sm:pr-0 pl-5 sm:pl-0 flex-col items-center">
              <Codecard bg="bg-teal" bg_text="text-lteal" scroll_pos="100" centerV fat="min-h-[7rem]">
                <div className="flex w-full sm:flex-nowrap flex-wrap justify-center items-center">
                  <h3 className="font-body z-10 text-white font-bold text-[25px] sm:text-[40px] relative text-center ">Experiences</h3>
                </div>
              </Codecard>
              <br></br>
              <Article dark={this.state.darkMode} titles={["NLP Summer Intern", "Research Intern NLP", "Software Engineering Intern"]} dates={["June - August 2021", "June - August 2022", "June - August 2024"]} places={["OrdrAi","Eastern Michigan University", "DoorList"]}
              descriptions={[
                `The main product of OrdrAi is a chatbot that accepts natural language orders and creates standardized orders sent directly to a store. 
                This allows customers to order quickly without using an app. Orders can be written quickly in a variety of ways, for example: "I want a lg pizza w/ a pepsi" or "Large pizza and a pepsi"
                As a software engineer intern for the summer, I worked on developing automated testing to evaluate the chatbot on new menus. Using Python, I created scripts that would read the menu JSON for
                a given store using an API, and generate tests for the chatbot and evaluate its performance automatically. This was useful for identifying improvements for the chatbot before deploying it to new stores
                with different menus.`
                ,
                `Converting computer code to English descriptions- natural language (nl) -and vice versa has many applications for both accelerating software development and making it more accessible. Working under Dr. Jiang from Eastern Michigan University, 
                we researched applying contrastive learning to improve current models for various code-nl conversion tasks using the CodeSearchNet dataset. I evaluated various versions CodeCSE model against other benchmark models, including
                CodeBERT, GraphCodeBERT, and more on the code-to-nl task (taking a code segment and converting it to an English description). I created a new model combining GraphCodeBERT with injected pretrained embeddings from our model to improve performance. `,
                `Designed, implemented, and took responsibility for a new in-app analytics feature using Flutter and Firebase to give users valuable insights into their social events. Developed a Python CLI to run integration tests that simulate the creation of events and other common user actions, allowing for faster and more rigorous testing for weekly releases. Collaborated closely with the UX designer, other developers, and the growth team to ensure successful deployment of analytics, CLI, and my other projects like an AI event banner generator.`
              ]}></Article>
            </div>
          </div>*/}

          

        </div>

        <div id="projects" className="relative">
          <div ref={this.projectHeader} className="absolute top-[-5%]"></div>
          <Divider></Divider>
        </div>

        {/*Projects*/}
        <div className="w-screen relative z-5 justify-center flex pl-5 pr-5  mt-10 sm:flex-nowrap flex-wrap">
          <Codecard bg="bg-teal" bg_text="text-lteal" scroll_pos="80" fat="min-h-[10rem]" centerV>
            <div className="flex w-full sm:flex-nowrap flex-wrap justify-center items-center">
            <h3 className="font-body z-10 text-white font-bold text-[50px] sm:text-[75px] relative text-center ">Projects</h3>
            </div>
            <div className="flex w-full sm:flex-nowrap flex-wrap items-center justify-center text-center">
            <h3 className="font-body z-10 text-white font-bold text-xl text-center ">Take a look at what I've been up to.</h3>
            </div>
          </Codecard>
        </div>

        <div className="w-screen relative grid grid-cols-1 sm:grid-cols-3 grid-rows-4 mt-10 justify-items-center">
          <ProjectCard bgImage="url('/api/casenowbanner.png')" title="Casenow" play="https://casenow.app" description="AI consulting market sizing mock interviews for helping students get better at casing.">

          </ProjectCard>
          <ProjectCard bgImage="url('/api/sweatmath3.png')" title="Sweat Math" play="https://sweatmath.org" description="A math fundamentals learning application that blends hard work with gaming for engaged practice.">
          </ProjectCard>
          <ProjectCard bgImage="url('/api/rainsmart.png')" title="Rainsmart" play="https://rainsmart.org" description="A notification service to remind you to turn off your sprinkler when it rains. Originally made in 8th grade and updated recently with new UI and bug fixes.">
          </ProjectCard>
          <ProjectCard bgImage="url('/api/connectmescreenshot.png')" title={<span>Huron<br></br>Connect Me<br></br> Website</span>} link="https://glitch.com/edit/#!/huron-connect-me" play="https://www.huronconnectme.org/" description="Website for the Huron Connect Me chapter. Pure CSS, no frameworks + used Google Sheets API to load member list!">
          </ProjectCard>
          <ProjectCard bgImage="url('/api/gamedevscreenshot.png')" link="https://github.com/YnotCode/VGDC-Website" title="Huron Game Development Website" play="https://hurongamedevs.com" description="Website showcasing Huron Game Development Club. Uses hybrids.js web components!">
          </ProjectCard>
          <ProjectCard bgImage="url('/api/hopscreenshot.png')" title="Hop & Run" play="https://ynotdev.itch.io/hop-and-run" description="A game where one character can only hop and one can only run. Submitted for Brackeys Game Jam '21">
          </ProjectCard>
          <ProjectCard bgImage="url('/api/mtmscreenshot.png')" note="Made with help from Jaegun Song, Ryan Ilgen, Adithya Laakso, and Philip Baldridge" play="https://hhs-vgddc.itch.io/murder-that-mansion" title="Battle That Mansion" description="A game about fighting household objects in a mansion. Made in the Huron Game Development Club">
          </ProjectCard>
          <ProjectCard bgImage="url('/api/quantumscreenshot.png')" link="https://glitch.com/edit/#!/quantum-numbers" play="https://quantum-numbers.glitch.me/" title="Quantum Numbers Website" description="A website explaining the concept of quantum numbers created for a Web Design course.">
          </ProjectCard>
          <ProjectCard bgImage="url('/api/blockscreenshot.png')" title="Bouncy Block" note="Made with the Huron Game Development Club for the GMTK Game Jam 2022" play="https://ynotdev.itch.io/bouncy-block" description="A game about using a giant dice block with random effects to attack enemies. Submitted for the GMTK Game Jam '22.">
          </ProjectCard>
          <ProjectCard bgImage="url('/api/election.jpg')" title="2020 Election Simulator" link="https://github.com/YnotCode/2020-Election-Simulator" description="A command line Java simulation of the 2020 U.S. presidential election. Play as Joe Biden and try to defeat Trump.">
          </ProjectCard>
          <ProjectCard bgImage="url('/api/textscreenshot.png')" link="https://github.com/YnotCode/Text-Trivia" title="Text Trivia" description="An SMS trivia game that was submitted for the We Can Jam 2020 hackathon.">
          </ProjectCard>
          
        </div>

        <div id="awards" ref={this.awardsHeader}>
        <div ref={this.awardsHeader} className="absolute top-[-5%]"></div>
          <Divider></Divider>
        </div>

        {/*Awards*/}
        <div className="w-screen relative z-5 justify-center flex pl-5 mt-10 mb-10 pr-5 sm:flex-nowrap flex-wrap">
          <Codecard bg="bg-tan" bg_text="text-ltan" scroll_pos="80" fat="min-h-[10rem]" centerV>
            <div className="flex w-full sm:flex-nowrap flex-wrap justify-center items-center">
            <h3 className="font-body z-10 text-raisin font-bold text-[50px] sm:text-[75px] relative text-center ">Awards</h3>
            </div>
            <div className="flex w-full sm:flex-nowrap flex-wrap items-center justify-center text-center">
            <h3 className="font-body z-10 text-raisin font-bold text-xl text-center ">It's flexin time.</h3>
            </div>
          </Codecard> 
        </div>
        
        <Award theme={this.state.darkMode ? "dark" : "light"} left date="April 2023" title="Coached Academic Games Team to win first-ever national championship for King Elementary School"></Award>
        <Award theme={this.state.darkMode ? "dark" : "light"} date="July 2022" title="AP Scholar with Distinction"></Award>
         <Award theme={this.state.darkMode ? "dark" : "light"} left date="February 2022" title="2021 Congressional App Challenge Winner"></Award>
        <Award theme={this.state.darkMode ? "dark" : "light"} date="May 2020" title="Youth CodeJam Best Hardware Hack & Most Innovative"></Award>
        

        <div id="contact">
          <Divider></Divider>
        </div>

        <div className="bg-raisin text-white w-full h-[50vh] flex flex-col gap-4 p-5 justify-center items-center">
          <div className="w-[50%] text-center">
            <h3 className="text-3xl font-body font-bold">Contact Information</h3>
          </div>
          <div className="w-[300px]">
            <TransparentButton whiteText click={function(){navigator.clipboard.writeText("avarkey@umich.edu")}} icon="mail">avarkey@umich.edu</TransparentButton>
          </div>
          <div className="w-[300px]">
            <TransparentButton whiteText click={function(){navigator.clipboard.writeText("734-323-8630")}} icon="call">734-323-8630</TransparentButton>
          </div>
          <div className="w-[300px]">
          <TransparentButton href="https://www.linkedin.com/in/anthonyvarkey" whiteText icon="alternate_email">linkedin.com/in/anthonyvarkey</TransparentButton>
          </div>
          
        </div>

      </div>
    );
  } 
}

export default App;