import "./output.css";
import React from "react";
import "./custom.css";

class Codecard extends React.Component{
    constructor(props){
        super(props);
        this.props = props;
        this.state = {
            codeMsg: "",
        }
        this.el = React.createRef();
        this.direction = 1;
    }

    componentDidMount(){

        /*var maxScroll = Math.max( document.body.scrollHeight, document.body.offsetHeight, 
            this.el.current.clientHeight, this.el.current.scrollHeight, this.el.current.offsetHeight );*/
        
        fetch("/api/Codecard.js").then((res)=>{
            res.text().then((text)=>{
                /*var secondLastIndex = text.lastIndexOf('}', text.lastIndexOf('}')-1)
                var start = text.lastIndexOf("render()");
                text = text.substring(start, secondLastIndex + 1);
                text = text.replace(/\t/g, "");
                text = text.replace(/ /g, "");*/
                this.setState({
                    codeMsg: text
                });
                this.el.current.scrollTop = this.props.scroll_pos;
                /*maxScroll = Math.max( document.body.scrollHeight, document.body.offsetHeight, 
                    this.el.current.clientHeight, this.el.current.scrollHeight, this.el.current.offsetHeight );*/
            }).catch(err=>{console.log("F")});
        }).catch(err=>{console.log("F")});


        var self = this;
        var prevCurr = 0;
        
        window.setInterval(function(){
            
            if (self.el.current == null || self.el == null){
                return;
            }
            let curr = parseInt(self.el.current.scrollTop);
            if (curr == prevCurr){
                self.direction *= -1;
            }
            prevCurr = curr;
            self.el.current.scrollTop = curr + 2 * self.direction + "";
        }, 100);

       /*function preventScroll(e){
            e.preventDefault();
            e.stopPropagation();
            document.body.scrollBy(0, 100)
            return false;
        }

        this.el.current.addEventListener('wheel', preventScroll, {passive: false});
        this.el.current.addEventListener('scroll', preventScroll, {passive: false});*/
    }

    render(){
        return (
            <div className={`platform flex ${this.props.bg} justify-center ${this.props.centerV ? "items-center" : ""} w-full mr-4 ml-4 sm:max-w-xl md:max-w-2xl p-5 relative max-h-80 ${this.props.fat ? this.props.fat: "min-h-[20rem]"}  bg-slate-700 rounded-xl`}>
                <div ref={this.el}  className={`w-full font-code z-0 absolute mr-5 top-0 left-0 p-5 h-full rounded-xl bg-slate-700 ${this.props.bg_text} overflow-y-hidden hiddenScrollbar break-words`}>{this.state.codeMsg}</div>
                <div>
                {this.props.children}
                </div>
            </div>
        )
    }
}

export default Codecard;