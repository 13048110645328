import React from "react";

class Divider extends React.Component{

    render(){
        return(
        <div>
            <div className="w-screen relative z-5 justify-center flex bg-green h-10"></div>
            <div className="w-screen relative z-5 justify-center flex bg-dgreen h-10"></div>
            <div className="w-screen relative z-5 justify-center flex bg-green h-10"></div>
        </div>
        );
    }

}

export default Divider;