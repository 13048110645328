import "./output.css";
import React from "react";
import "./custom.css";
import "./TransparentButton.js";

class ProjectCard extends React.Component{
    constructor(props){
        console.log("SOMETHING")
        super(props);
        this.props = props;
        this.el = React.createRef();
        this.teamEl = React.createRef();
        this.descHidden = true;
        this.direction = 1;
        this.teamHidden = true;
    }

    toggleDesc = ()=>{
        this.teamHidden = true;
        console.log("HI");
        this.teamEl.current.style.display = "none";
        if (this.descHidden){
            this.el.current.style.display = "flex";
            this.el.current.className = this.el.current.className.replace("slide-out-bottom", "slide-top");
        }
        else{
            this.el.current.className = this.el.current.className.replace("slide-top", "slide-out-bottom");
        }
        this.descHidden = !this.descHidden;
    }   

    toggleTeam = ()=>{
        this.descHidden = true;
        this.el.current.style.display = "none";
        if (this.teamHidden){
            this.teamEl.current.style.display = "flex";
            this.teamEl.current.className = this.teamEl.current.className.replace("slide-out-bottom", "slide-top");
        }
        else{
            this.teamEl.current.className = this.teamEl.current.className.replace("slide-top", "slide-out-bottom");
        }
        this.teamHidden = !this.teamHidden;
    }

    render(){
        var self = this;
        return (
            <div style={{"backgroundImage": this.props.bgImage, "backgroundSize":"cover"}} className={` bg-opacity-20 platform flex flex-col justify-center items-center ${this.props.bg} w-[70%] text-center h-0 pb-[35%] pt-[35%] max-h-0 mb-10 relative bg-slate-700 rounded-xl overflow-hidden`}>
                <div className="absolute top-0 left-0 w-full bg-raisin p-2 rounded-t-xl rounded-b-none flex justify-end">
                    <button onClick={this.toggleDesc}  className={`hover:sepia-[0.5] rounded-full relative z-10 h-10 bg-tan text-sm font-bold font-body mr-2 flex justify-center items-center gap-2 p-3 text-raisin`}><span className="material-symbols-outlined">sms</span>More</button>
                    <a href={this.props.link} target="_blank" className={`hover:sepia-[0.5] ${this.props.link ? "flex" : "hidden"} rounded-full relative z-10 h-10 bg-teal text-sm font-bold font-body mr-2 flex justify-center items-center gap-2 p-3 text-white`}><span className="material-symbols-outlined">code</span>Code</a>
                    <button onClick={this.toggleTeam}  className={`hover:sepia-[0.5] rounded-full relative z-10 w-10 h-10 ${this.props.note ? "" : "hidden"} bg-green mr-2 flex justify-center items-center text-raisin`}><span className="material-symbols-outlined">group</span></button>
                    {/*<a href={this.props.play}  target="_blank" className={`rounded-full relative z-10 w-10 h-10 bg-red mr-2 flex justify-center items-center ${this.props.play ? "" : "hidden"} text-white`}><span className="material-symbols-outlined">open_in_browser</span></a>*/}
                </div>
                <div>
                    <h3 className="font-body font-bold text-white text-3xl pb-1">{this.props.title}</h3>
                </div>
                <a href={this.props.play} className={`hover:sepia-[0.5] ${this.props.play ? "flex" : "hidden"} bg-red text-lg text-white top-[75%] absolute items-center justify-center text-left rounded-full h-15 pr-3 p-3 pl-3 font-bold gap-2 font-body w-[50%]`}>
                    <span className={` material-symbols-outlined`}>open_in_browser</span> Try
                </a>
                <div ref={this.el} onClick={function(){self.toggleDesc();}} className="slide-top h-full absolute top-[95%] left-0 w-full bg-tan p-2 rounded-t-xl rounded-b-none justify-left hidden">
                    <p className="text-raisin font-body font-semibold">{this.props.description}</p>
                </div>
                <div ref={this.teamEl} onClick={function(){self.toggleTeam();}} className="slide-top h-full absolute top-[100%] left-0 w-full bg-green p-2 rounded-t-xl rounded-b-none justify-left hidden">
                    <p className="text-raisin font-body font-semibold">{this.props.note}</p>
                </div>
            </div>
        )
    }
}

export default ProjectCard;