import "./output.css";
import React from "react";
import "./custom.css";

class Card extends React.Component{
    constructor(props){
        super(props);
        this.props = props;
        this.el = React.createRef();
        this.direction = 1;
    }

    render(){
        return (
            <div className={`platform flex ${this.props.bg} justify-center ${this.props.centerV ? "items-center" : ""} w-full mr-4 ml-4 sm:max-w-xl md:max-w-2xl p-5 flex-grow-0 relative ${this.props.fat ? this.props.fat : "min-h-[20rem]"}  bg-slate-700 rounded-xl ${this.props.extraStyles}`}>
                <div>
                {this.props.children}
                </div>
            </div>
        )
    }
}

export default Card;