import React from 'react'

class SquareImage extends React.Component{
    constructor(props){
        super(props);
        this.props = props;
    }

    render(){
        return (
            /*<div style={{backgroundImage: "url(" + this.props.src + ")", backgroundSize: "cover"}} className={`w-[${this.props.size ? this.props.size : "60%"}] h-0 pt-[${this.props.size ? parseInt(this.props.size.replace("%", ""))/2 + "%" : "30%"}] pb-[${this.props.size ? parseInt(this.props.size.replace("%", ""))/2 + "%" : "30%"}] relative z-20`}>
                <div className={`bg-[rgba(255,255,255,0.5)] ${this.props.overlayBg ? "absolute" : "hidden"} top-[50%] left-0 w-full h-full z-0 rounded-xl`}></div>
            </div>*/

            <div style={{"backgroundImage": this.props.src, "backgroundSize":"cover", backgroundPositionX: this.props.x ? this.props.x : "", backgroundPositionY: this.props.y}} className={` platform flex flex-col justify-center items-center ${this.props.bg} w-[${this.props.size ? this.props.size : "70%"}] text-center h-0 pb-[${this.props.size ? parseInt(this.props.size)/2 + "%" : "35%"}] pt-[${this.props.size ? parseInt(this.props.size)/2 + "%" : "35%"}] max-h-0 mb-10 relative bg-[rgba(255,255,255,0.5)] rounded-xl overflow-hidden`}>
            </div>
        );
    }
}

export default SquareImage;