import "./output.css";
import React from "react";
import "./custom.css";
import BlankArticle from "./BlankArticle.js"

class Article extends React.Component{

    constructor(props){
        super(props);
        this.props = props;
        this.index = this.props.titles.length - 1;
        console.log(this.props.titles[this.props.titles.length - 1]);
        this.state = {
            "title": this.props.titles[this.props.titles.length - 1],
            "date": this.props.dates[this.props.dates.length - 1],
            "place": this.props.places[this.props.places.length - 1],
            "description": this.props.descriptions[this.props.descriptions.length - 1]
        }
    }

    goBack = ()=>{
        if (this.index > 0){
            this.index -= 1;
            var self = this;
            this.setState((prev)=>({
                "title": self.props.titles[self.index],
                "date": self.props.dates[self.index],
                "description": self.props.descriptions[self.index],
                "place": self.props.places[self.index]
            }));
        }
    }

    goForward = ()=>{
        if (this.index < this.props.titles.length - 1){
            this.index += 1;
            var self = this;
            this.setState((prev)=>({
                "title": self.props.titles[self.index],
                "date": self.props.dates[self.index],
                "description": self.props.descriptions[self.index],
                "place": self.props.places[self.index]
            }));
        }
    }

    render(){
        var self = this;
        return (
           <BlankArticle full theme={this.props.dark ? "dark" : "light"} borderColor="border-teal">
                <div className="flex justify-center gap-4">
                    <div className="flex pt-2 flex-row justify-center">
                        <button onClick={self.goBack} className={`${this.index == 0 ? "hidden" : ""} hover:sepia-[0.9] max-w-[300px] flex flex-shrink-0 flex-grow-0 relative items-center justify-center text-left rounded-full h-10 pr-3 pl-3 font-bold gap-2 font-body ${this.props.dark ? "bg-white text-raisin" : "bg-raisin text-white"}`}>
                            <span className={`material-symbols-outlined  ${this.props.dark ? "text-raisin" : "text-white"}`}>arrow_back</span>Previous: {this.index > 0 ? this.props.titles[this.index - 1] : ""}
                        </button>
                    </div>
                    <div className="flex pt-2 flex-row justify-center">
                        <button onClick={self.goForward} className={`${this.index == this.props.titles.length - 1 ? "hidden" : ""} hover:sepia-[0.9] flex flex-shrink-0 flex-grow-0 relative items-center justify-center text-left rounded-full h-10 pr-3 pl-3 font-bold gap-2 font-body  ${this.props.dark ? "bg-white text-raisin" : "bg-raisin text-white"}`}>
                        Next: {this.index != this.props.titles.length - 1 ? this.props.titles[this.index + 1] : ""}<span className={`material-symbols-outlined  ${this.props.dark ? " text-raisin" : "text-white"}`}>arrow_forward</span>
                        </button>
                    </div>
                </div>
                <div className="font-body font-bold p-0 pt-5 sm:p-5">
                    <h3 className="text-3xl underline underline-offset-8 decoration-green decoration-solid decoration-[5px]">{this.state.title}</h3>
                    <br></br>
                    <h5 className="text-xl"><span className="underline underline-offset-8 decoration-tan decoration-solid decoration-[5px]">Date:</span><br></br><br></br>{this.state.date}</h5>
                    <br></br>
                    <h5 className="text-xl"><span className="underline underline-offset-8 decoration-red decoration-solid decoration-[5px]">Organization:</span><br></br><br></br>{this.state.place}</h5>
                    <br></br>
                    <h5 className="text-lg"><span className="underline underline-offset-8 decoration-teal decoration-solid decoration-[5px]">What I did:</span><br></br><br></br>{this.state.description}</h5>
                </div>
           </BlankArticle>
        );
    }
}

export default Article;