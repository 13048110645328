import React from "react";
import Card from "./Card.js";

class Award extends React.Component{

    constructor(props){
        super(props);
        this.props = props;
    }

    render(){

        if (this.props.left){
            return (
                <div className="flex w-full flex-wrap sm:flex-nowrap justify-start items-center mb-10">
                    <div className="flex flex-nowrap items-center w-full sm:w-[40%] m-2 sm:m-0">
                        <div className="w-[40%] flex-grow-0 bg-green h-5"></div>
                        <h3 className={`font-body flex-grow-1 mr-5 ml-5 z-10 ${this.props.theme == "dark" ? "text-white" : "text-raisin"} font-bold text-xl sm:text-3xl text-center`}>{this.props.date}</h3>
                        <div className="w-[40%] flex-grow-0 bg-green h-5"></div>
                    </div>
                    <Card extraStyles="bg-gradient-to-r from-tan to-ltan" bg="bg-tan" bg_text="text-ltan" fat="min-h-[7rem]" scroll_pos="80">
                        <div className="flex w-full flex-grow sm:flex-nowrap flex-wrap items-center justify-center">
                            <h3 className="font-body z-10 text-raisin font-bold self-center text-base sm:text-2xl">{this.props.title}</h3>
                            {/*<div className="flex justify-end">
                            <a href="#home" className={`flex-grow-0 rounded-full w-10 ml-5 hidden sm:flex h-10 text-raisin bg-[rgba(255,255,255,0.25)] justify-center items-center`}><span className="material-symbols-outlined">sms</span></a>
                            </div>*/}
                        </div>
                        {/*<div className="flex w-full flex-grow sm:flex-nowrap flex-wrap mt-5">
                            <p className="font-body z-10 text-raisin font-bold text-xs sm:text-lg text-center">Lorem ipsum dolor sit aokfnonsdooidovno</p>
                        </div>*/}
                    </Card> 
                </div>
            );
        }
        else{
            return (
                <div className="flex w-full flex-wrap sm:flex-nowrap justify-end items-center mb-10">
                    <Card extraStyles="bg-gradient-to-r from-tan to-ltan order-2" bg="bg-tan" bg_text="text-ltan" fat="min-h-[7rem]" scroll_pos="80">
                        <div className="flex w-full flex-grow sm:flex-nowrap flex-wrap items-center text-left justify-start">
                            <h3 className={`font-body z-10 font-bold text-base sm:text-2xl text-raisin`}>{this.props.title}</h3>
                            <div className="flex justify-end">
                           {/* <a href="#home" className={`flex-grow-0 rounded-full w-10 ml-5 hidden sm:flex h-10 text-raisin bg-[rgba(255,255,255,0.25)] justify-center items-center`}><span className="material-symbols-outlined">sms</span></a>*/}
                            </div>
                        </div>
                        {/*<div className="flex w-full flex-grow sm:flex-nowrap flex-wrap mt-5">
                            <p className="font-body z-10 text-raisin font-bold text-xs sm:text-lg text-center ">Lorem ipsum dolor sit aokfnonsdooidovno</p>
                        </div>*/}
                    </Card> 
                    <div className="flex flex-nowrap items-center w-full sm:w-[40%] m-2 sm:m-0 order-1 sm:order-3">
                        <div className="w-[40%] flex-grow-0 bg-green h-5"></div>
                        <h3 className={`font-body flex-grow-1 mr-5 ml-5 z-10 font-bold text-xl  ${this.props.theme == "dark" ? "text-white" : "text-raisin"} sm:text-3xl text-center`}>{this.props.date}</h3>
                        <div className="w-[40%] flex-grow-0 bg-green h-5"></div>
                    </div>
                </div>
            );
        }

        
    }
}

export default Award;